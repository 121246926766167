import getPairData from "../../utils/TickerGetPairData"

export function updateCurrentPair(){
    return (dispatch, getState) => {
        let tickerState = getState().ticker;

        const pair = getPairData(tickerState.current_id, tickerState.pairs);

        //console.log('updateCurrentPair', tickerState.current_id, tickerState.pairs, pair);

        dispatch({
            type: 'SET_CURRENT_PAIR',
            payload: pair
        });
    }
}