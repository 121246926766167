import axios from '../../../utils/Api';

function fetchActiveCurrenciesConfig() {
  return (dispatch) => {
    axios({ url: '/api/default/active-currencies', method: 'POST' })
      .then(response => {
        return response.data;
      })
      .then(data => dispatch({
        type: 'FETCH_ACTIVE_CURRENCIES',
        payload: data
      }))
      .catch(() => { });
  }
}

export default fetchActiveCurrenciesConfig;
