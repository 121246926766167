import React from "react";
import cn from "classnames";
import styles from "./Loader.module.sass";

const Loader = ({className, color}) => {
    return (
        <div className={cn(styles.center, styles.container)}>
            <div className={cn(styles.loader, className, {
                [styles.loaderWhite]: color === "white",
            })}
            ></div>
        </div>
    );
};

export default Loader;
