import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";

import cn from "classnames";
import styles from "./Confirm.module.sass";

import axios from "../../../utils/Api";

const page = 'wallet.withdraw.confirm';

const Confirm = ({ orderData, handleClose, coinType }) => {
    const { t } = useTranslation();

    const handleConfirm = async () => {
        const toastConfirm = toast.loading('Please wait...');

        const response = await axios.post(
            coinType === 1 ? '/api/withdraw/confirm' : '/api/fiat/withdraw-confirmation',
            {
                send_to_email: 1,
                send_to_phone: 1,
                id: orderData.id
            }
        );

        const data = response.data;

        if (data.status) {
            toast.success(`${t(`Confirmation codes were sent.`)}`, {
                id: toastConfirm
            })
            handleClose(data.data.required);
        } else {
            const errorText = data.data.errors ? Object.values(data.data.errors)[0] : 'unknown';

            if (data.data.errors && errorText === 343) {
                toast.error(`${t('toast.error')}: ${t('toast.errorCode.343')}.`, {
                    id: toastConfirm,
                });
            } else {
                toast.error(`${t('toast.error')}: ${errorText}`, {
                    id: toastConfirm,
                });
            }
        }
    };

    /**
     * Render HTML
     */
    return (
        <div className={styles.withdraw}>
            <div className={cn("h4", styles.title)}>
                {t(`${page}.title`)}
            </div>
            <img loading="lazy" src={orderData.image} alt="confirm" style={{ maxWidth: '100%', marginBottom: '20px' }} />
            <div className={styles['button-container']}>
                <button className={cn("button", styles.button)} onClick={handleConfirm}>
                    {t(`${page}.submit`)}
                </button>
            </div>
        </div>
    );
}

export default Confirm;