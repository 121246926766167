import TraderPrepare from "../../../utils/TraderPrepare";

export function updateActiveMarginOrdersData(orders) {
    return (dispatch, getState) => {
        const config = getState().config;

        const ready_orders = TraderPrepare.prepareUsersOrders(orders, config.all_pairs, 'margin');

        dispatch({
            type: 'UPD_ORDERS_MARGIN',
            payload: ready_orders
        });

        dispatch({
            type: 'MARGIN_ORD_READY_FLAG',
            payload: true
        });
    }
}

export function updateMarginHistoryOrdersData(orders) {
    return (dispatch, getState) => {
        const config = getState().config;

        const orders_list = getState().user.mod;

        for (const order of orders) {
            for (const order_id in orders_list) {
                if (+order.id === +order_id) {
                    dispatch({
                        type: 'DELETE_MARGIN_ORDER',
                        payload: { index: order_id }
                    });
                }
            }
        }

        const ready_margin_history_orders = TraderPrepare.prepareUsersOrders(
            orders,
            config.all_pairs,
            "margin"
        );

        dispatch({
            type: 'UPD_ORDERS_MARGIN_HISTORY',
            payload: ready_margin_history_orders
        });

        dispatch({
            type: 'MARGIN_HISTORY_ORD_READY_FLAG',
            payload: true
        });
    }
}