export function ordersMergeArrays(socketOrders, restOrders) {
    const socket_count = socketOrders.length;
    const rest_count = restOrders.length;

    for (let i = 0; i < socket_count; i += 1) {
        for (let r = 0; r < rest_count; r += 1) {
            if (Number(socketOrders[i].rate) === Number(restOrders[r].rate)) {
                if (Number(socketOrders[i].time) > Number(restOrders[r].time)) {
                    if (socketOrders[i].volume !== 0) {
                        restOrders[r] = socketOrders[i];
                    } else {
                        restOrders.splice(r, 1);
                    }
                }

                break;
            }
        }
    }

    return restOrders;
}