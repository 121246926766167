import i18n from "../../localization/index";

const initialState = {
    session_flag: false,
    session_modal: false,
    captcha_status: false,
    captcha_warning: false,
    referral_info: null,
    user_profile: undefined,
    address_book: undefined,
    user_chat_data: undefined,

    // user_referrals_data: null,
    // referral_link: null,

    referralInfo: {
        count: null,
        info: []
    },
    user_referrals_data: null,
    referral_link: null,

    username_verification: null,
    user_documents: [],
    doc_v_details: {},
    username_credentials: {},
    username_sent: false,

    account_security: {
        security_code: 0,
        authentication_types: ['disabled', 'email', 'google_auth'],
        ready: false,
        required: null,
    },

    data_required: null,
    two_factor_sender_state: false, // state open/close

    document_types: ['Passport', 'Driver`s license', 'ID card', 'Bank statement', 'Utility bills'],
    document_statuses: ['wait_confirm', 'approved', 'canceled'],
    username_v_statuses: ['not_verified', 'on_moderation', 'approved'],

    username_submitted: false,
    request_unique_id: 0,
    redirect_to_page: null,
    withdraw_secutity_statys: {},
    account_security_withdraw: null,
    not_verified_email: '',
    acc_details_to_verify_email: null,
    
    toastErrorId: null,
    alert: false,
    isCaptchaLoading: false
};

export default function (state = initialState, action) {
    const data = action.payload;

    switch (action.type) {
        case 'SHOW_TOAST_ERROR':
            return {...state, toastErrorId: data}
        case 'SET_ALERT':
            return {...state, alert: data}
        case 'SET_IS_CAPTCHA_LOADING':
            return {...state, isCaptchaLoading: data}
        case 'DATA_REQUIRED': {
            return {...state, data_required: data}
        }
        default:
            return state;
    }
}