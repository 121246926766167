import TraderPrepare from "../../utils/TraderPrepare";

export function ordersSortArray(curr_orders, state, type) {
    // Sorting
    if (type === 'sell') {
        curr_orders.sort((a, b) => a.rate - b.rate);

        state.sellOrders = curr_orders;
    } else {
        curr_orders.sort((a, b) => b.rate - a.rate);

        state.buyOrders = curr_orders;
    }

    const ordersData = TraderPrepare.prepareBookOrders(curr_orders, state.book_lines);

    if (type === 'sell') {
        state.volumes.total_sell = ordersData.total;
        state.volumes.total_both = ordersData.total + state.volumes.total_buy;
        state.sellOrders_show = ordersData.orders;
    } else {
        // state.book_resp_come = true;
        state.volumes.total_buy = ordersData.total;
        state.buyOrders_show = ordersData.orders;
    }

    return state;
}