import axios from "../../../utils/Api";

export function initialTradesData(pairId) {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            const tickerState = getState().ticker;
            const currentPairId = pairId || Number(tickerState.current_id);
            const limit = 100;

            axios.get(`${process.env.REACT_APP_API_SOCKET_URL}/book/hist?pair_id=${currentPairId}&limit=${limit}&p=5`)
                .then((resp) => {
                    if (
                        resp.status === 200
                        && !resp.error
                        && Number(tickerState.current_id) === Number(currentPairId)
                    ) {
                        dispatch({
                            type: 'TRADES_ORD_READY_FLAG',
                            payload: true
                        });

                        if (!resp.data.hist) {
                            dispatch({
                                type: 'RESET_TRADES_ORDERS',
                                payload: ''
                            });

                            resolve(resp.data);
                        } else {
                            dispatch({
                                type: 'ADD_TRADES_ORDERS',
                                payload: resp.data.hist
                            });

                            resolve(resp.data);
                        }
                    } else {
                        dispatch({
                            type: 'TRADES_ORD_READY_FLAG',
                            payload: false
                        });
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: 'TRADES_ORD_READY_FLAG',
                        payload: true
                    });
                    dispatch({
                        type: 'SERVER_500_TRADES_ORDERS',
                        payload: true
                    });
                });
        });
    }
}
