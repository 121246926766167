import axios from "../../../utils/Api";

//async
export function currencyInfoRequest() {
    return (dispatch) => {
        return new Promise((resolve) => {
            axios.get('/api/default/descriptions')
                .then((resp) => {
                    if (resp.status === 200) {
                        dispatch({type: 'SET_CURRENCY_DESCRIPTIONS', payload: resp})

                        resolve('success');
                    } else {
                        resolve('error');
                    }
                })
                .catch((err) => {});
        });
    }
}