import {updateSocketSubscribes} from "../Sockets/UpdateSocketSubscribes";
import {socket} from "../../../context/socket";

export function updateBookDeltaOnSocket(type, message){
    return (dispatch, getState) => {
        let tickerState = getState().ticker;

        if (Number(message.room.split(`${type}_`)[1]) === Number(tickerState.current_id)) {
            dispatch({
                type: 'UPDATE_ORDERS_DELTA',
                payload: message.data
            });
        } else {
            dispatch(updateSocketSubscribes(socket));
        }
    }
}
