import {updateSocketSubscribes} from "../Sockets/UpdateSocketSubscribes";
import {socket} from "../../../context/socket";

export function updateBookSnapshotOnSocket(type, message){
    return (dispatch, getState) => {
        let tickerState = getState().ticker;

        if (Number(message.room.split(`${type}_`)[1]) === Number(tickerState.current_id)) {
            dispatch({type: 'RESET_BUY_ORDERS_LIST', payload: ''});
            dispatch({type: 'RESET_SELL_ORDERS_LIST', payload: ''});

            dispatch({type: 'GOT_INITIAL_ORDERS_LIST', payload: message.data});
        } else {
            dispatch(updateSocketSubscribes(socket));
        }
    }
}
