const subscribe = (socket, subs) => {
    socket.emit('subscribe', subs);
};

const unsubscribe = (socket, unsubs) => {
    socket.emit('unsubscribe', unsubs);
};

export function updateSocketSubscribes(socket) {
    return (dispatch, getState) => {
        let socketState = getState().sockets;
        let tickerState = getState().ticker;

        // Check current subscription
        if(socketState.subscribed.length > 0){
            if (socketState.subscribed[0].includes((tickerState.current_id).toString())) {
                return false;
            }
        }

        // console.log('updateSocketSubscribes', tickerState.current_id, socketState.subscribed);

        // Do unsubscribe and new subscribe
        return new Promise((resolve) => {
            // Unsubscribe from all channels
            const subscribesCount = socketState.subscribed.length;

            for (let i = 0; i < subscribesCount; i += 1) {
                unsubscribe(socket, socketState.subscribed[i]);
            }

            dispatch({
                type: 'RESET_SUBSCRIBED',
                payload: ''
            })

            // Subscribe to new channels
            const toSubscribesCount = socketState.to_subscribe.length;

            let event;
            for (let i = 0; i < toSubscribesCount; i += 1) {
                event = socketState.to_subscribe[i] + tickerState.current_id;

                subscribe(socket, event);

                dispatch({
                    type: 'ADD_TO_SUBSCRIBED',
                    payload: event
                })
            }

            resolve('subscribe updated');
        })
    }
}
