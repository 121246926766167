export const TYPE_LOGIN = {
  OFF: 0,
  ON_MAIL: 1,
  ON_GOOGLE: 2,
  ON_PHONE: 3,
  ON_PERSONAL_PIN: 5
}

export const TYPE_LOGIN_FIELD_NAMES = {
  1: 'email_code',
  2: 'google_code',
  3: 'phone_code',
  5: 'personal_pin',
}