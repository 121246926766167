export function setCurrentBalance() {
    return (dispatch, getState) => {
        const user = getState().user;
        let ticker = getState().ticker;

        const main_name = ticker.current_main_name;
        const second_name = ticker.current_sec_name;

        const current_balances = {
            main_currency: {
                balance: user.user_balances[main_name] === undefined ? 0 : user.user_balances[main_name].total_balance,
                available: user.user_balances[main_name] === undefined ? 0 : user.user_balances[main_name].total_available,
            },
            second_currency: {
                balance: user.user_balances[second_name] === undefined ? 0 : user.user_balances[second_name].total_balance,
                available: user.user_balances[second_name] === undefined ? 0 : user.user_balances[second_name].total_available,
            },
        };

        dispatch({
            type: 'SET_CURRENT_BALANCES',
            payload: current_balances
        });

        return true;
    }
}