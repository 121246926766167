import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

const files = require.context('.', false, /\.json$/);
const locales = {};
locales.available = {};

files.keys().forEach((key) => {
    const code = key.replace(/(\.\/|\.json)/g, '');

    locales[code] = files(key);
    locales.available[code] = code;
});

export const availableLangs = Object.values(locales.available);

i18n
    .use(initReactI18next)
    // .use(LanguageDetector)
    .init({
        supportedLngs: ["ar", "de", "el", "en", "fa", "fr", "id", "it", "lt", "ms", "nl", "pl", "pt", "ru", "sv", "th", "tl", "tr", "uk", "vi", "zh_CN", 'es', 'hi'],
        lng: localStorage.getItem('lngCode') ?? process.env.REACT_APP_DEFAULT_LANG,
        fallbackLng: process.env.REACT_APP_DEFAULT_LANG,
        debug: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        resources: locales
    });

export default i18n;
