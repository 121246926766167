import React, { forwardRef } from "react";
import styles from "./NumberValidatedInput.module.sass";


window.addEventListener('wheel', (e) => {
    if (e && e.target && e.target.dataset && e.target.dataset.disableWheel) {
        e.preventDefault();
    }
}, { passive: false })


const NumberValidatedInput = forwardRef(({ onWrongPaste = null, className = '', ...props }, ref) => {
    const handleValuePaste = async (e) => {
        let data = NaN;

        if (e?.clipboardData?.getData) {
            data = e.clipboardData.getData('text');
        } else {
            if (window?.navigator?.clipboard?.readText) {
                data = await window.navigator.clipboard.readText().catch(e => NaN);
            }
        }

        if (!isNaN(data)) {
            return;
        }

        e.preventDefault();

        if (onWrongPaste) {
            onWrongPaste();
        }
    }

    const handleValueInput = (e) => {
        if (e.keyCode === 65 && e.ctrlKey) { //ctrl + a
            return;
        }

        if (e.keyCode === 90 && e.ctrlKey) { //ctrl + z
            return;
        }

        if (e.keyCode === 88 && e.ctrlKey) { //ctrl + x
            return;
        }

        if (e.keyCode === 67 && e.ctrlKey) { //ctrl + c
            return;
        }

        if (e.keyCode === 86 && e.ctrlKey) { //ctrl + v
            return;
        }

        if ([8, 46, 16, 9, 37, 39].includes(e.keyCode) || !isNaN(e.key) || (e.key === '.' && !e.target.value.includes('.'))) {
            return;
        }

        e.preventDefault();
    }

    return (
        <input
            type="number"
            step="any"
            min="any"
            max="any"
            className={className + ' ' + styles.inputNumber}
            ref={ref}
            data-disable-wheel={true}
            {...props}
            onKeyDown={handleValueInput}
            onPaste={handleValuePaste}
        />
    );
});

export default NumberValidatedInput;
