import session_manager from "../../../utils/SessionManager";
import getPairData from "../../utils/TickerGetPairData";
import {initialTradesData} from "../Trades/InitialTradesData";
import {initialOrdersBookData} from "../OrdersBook/InitialOrdersBookData";
import {currencyInfoRequest} from "../Config/CurrencyInfoRequest";
import {setDemoBalances} from "../User/SetDemoBalances";
import {setCurrentBalance} from "../User/SetCurrentBalance";
import {fetchUserBalances} from "../User/FetchUserBalances";
import axios from "../../../utils/Api";
import {updateSocketSubscribes} from "../Sockets/UpdateSocketSubscribes";
import {socket} from "../../../context/socket";

export function changeCurrentPair(pairId, load = false) {
    return (dispatch, getState) => {
        const tickerState = getState().ticker;
        const config = getState().config;

        if (pairId === '' || pairId === undefined) {
            pairId = config.default_pair_id;
        }
        pairId = Number(pairId);

        // Update PairId in storage
        session_manager.setCurrentPairId(pairId);

        let pairs = tickerState.pairs;

        if(load){
            axios.get('/api/default/ticker')
                .then(response => {
                    if (response.status === 200 && !response.error) {
                        // console.log('changeCurrentPair response', response.data.data);
                        pairs = response.data.data;

                        const newPair = getPairData(pairId, pairs);

                        dispatch({
                            type: 'SET_CURRENT_PAIR',
                            payload: newPair
                        });

                        dispatch({
                            type: 'SET_CURRENT_PAIR_INFOBOX_DATA',
                            payload: newPair
                        });
                    }
                })
                .catch(()=>{});
        }

        dispatch({
            type: 'SET_CURRENT_PAIR',
            payload: getPairData(pairId, pairs)
        });

        if(session_manager.getDemoFlag()){
            dispatch(setDemoBalances());
        }else{
            dispatch(setCurrentBalance());
        }

        dispatch(initialTradesData());

        // book update
        dispatch(initialOrdersBookData(pairId));

        dispatch(currencyInfoRequest());

        // set new pair data to user balance store
        dispatch(fetchUserBalances());

        // Do subscriber on pair
        dispatch(updateSocketSubscribes(socket));
    }
}
