const initialState = {
    alerts: {
        list: [],
        pagination: {
            page: 0,
            totalCountPage: 0
        },
        total_count: 0,
    },
    alertsIsLoading: false,
};

export default function (state = initialState, action) {
    const data = action.payload;

    switch (action.type) {
        case 'SET_PRICE_ALERTS_LIST': {
            return {
                ...state,
                alerts: data
            }
        }
        case 'SET_PRICE_ALERTS_LOADING': {
            return {
                ...state,
                alertsIsLoading: data
            }
        }
        default:
            return state;
    }
}