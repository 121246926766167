import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import cn from "classnames";
import styles from "./Activity.module.sass";

import Table from "./Table";
import Loader from "../../../components/Loader";

import getTransactionsList from "../../../store/actions/User/GetTransactionsList";
import { fetchUserIEOList } from "../../../store/actions/User/FetchIEO";

import TraderPrepare from "../../../utils/TraderPrepare";

//const navigation = [
//    "All type",
//    "Withdrawals",
//    "Deposit",
//    "Transfer",
//    "Bank credit",
//];

const walletNames = {
    spot: 'Spot',
    funding: 'Funding',
    margin: 'Margin'
};

const TRANSFER_TYPES = {
    10: "Transfer",
    20: "Reward for Affiliate",
}

const Activity = ({ id: idInit }) => {
    const [ticker] = useState(idInit);
    const [items, setItems] = useState();

    /**
     *
     */
    const all_icons = useSelector(state => state.config.all_icon);
    const all_currencies = useSelector(state => state.config.all_currencies);
    const transactionsList = useSelector(state => state.user.transactionsList);
    const transactionStatuses = useSelector(state => state.user.transactionStatuses);
    const IEOList = useSelector(state => state.user.ieo_list);
    const config_ready = useSelector(state => state.config.configs_ready.all_pairs);
    const { t } = useTranslation();

    const orderTypes = {
        0: t(`orderTypes.0`),     // type_trade = 0 Limit
        1: t(`orderTypes.1`),     // type_trade = 1 Market
        2: t(`orderTypes.2`),     // type_trade = 2 Stop-Limit
    }

    const transactionTypeNames = {
        'TRADE': t('transactionTypeNames.trade'),
        'DEPOSIT': t('transactionTypeNames.deposit'),
        'WITHDRAW': t('transactionTypeNames.withdraw'),
        'INTERNAL_TRANSFER': t('transactionTypeNames.transfer'),
        'REFERRAL_BONUS': t('transactionTypeNames.referralBonus'),
        'AFFILIATE_REWARD': t('transactionTypeNames.affiliateReward'),
    }

    /**
     *
     * @type {Dispatch<AnyAction>}
     */
    const dispatch = useDispatch();

    const currency_id = useMemo(() => {
        const all_currencies_flip = Object.entries(all_currencies)
            .reduce((obj, [key, value]) => ({ ...obj, [value]: key }), {});

        return all_currencies_flip[ticker];
    })

    const tickerDecimalRounding = useSelector(state => {
        return state.config.currency_data[currency_id].decimal_rounding
    });

    /**
     *
     */
    useEffect(() => {
        if (!config_ready) {
            return;
        }

        dispatch(getTransactionsList(currency_id));
        dispatch(fetchUserIEOList());
    }, [config_ready, dispatch])

    useEffect(() => {
        // console.log(openedOrders, list_orders_transfer, list_orders_withdraw, list_orders_deposits);

        const fetchActivityData = async () => {
            if (!Object.keys(all_icons).length) {
                return;
            }

            /**
             * Add deposits orders
             */
            const formattedTransactions = [];
            for (let key in transactionsList) {
                const x = transactionsList[key];

                const formattedAddress =
                    (x?.to_address?.length > 20)
                        ? x?.to_address?.substring(0, 19).concat('...')
                        : x?.to_address

                const formattedDate = (new Date(x.time_create * 1000)).toLocaleString();

                let txid = null;
                if (x.txid) {
                    txid = x.txid.substring(0, 5).concat('...', x.txid.substring(x.txid.length - 5));
                }

                let transactionType = transactionTypeNames[x.type] ?? x.type;

                if (x.type === 'TRADE') {
                    transactionType = x.trade ? `${orderTypes[x.trade.order_type]} ${x.trade.order_direction}` : transactionTypeNames[x.type]
                }

                formattedTransactions.push({
                    ...x,
                    type: transactionType,
                    type_raw: x.type,
                    status_raw: x.status,
                    currency: x.iso3,
                    image: all_icons[x.iso3],
                    amount: TraderPrepare.formatNumCurrency(x.amount, tickerDecimalRounding),
                    address: formattedAddress,
                    fullAddress: x.to_address,
                    status: transactionStatuses[x.status],
                    comment_from_admin: x?.comment_from_admin,
                    date: formattedDate,
                    timestamp: x.time_create,
                    url: "/activity",
                    txid: txid,
                    txid_explore: x.txid_explore,
                });
            }

            const formattedIEOList = [];
            Object.values(IEOList).forEach(x => {
                let amount = 0;
                let amount_bonus = 0;

                for (let i = 0; i < x.orders.length; i++) {
                    amount += parseInt(x.orders[i].amount);
                    amount_bonus += parseFloat(x.orders[i].amount_bonus);
                }

                x.orders.forEach(order => {
                    const formattedDate = new Date(order.time_create * 1000).toLocaleString();

                    formattedIEOList.push({
                        ieoSell: true,
                        currency: order.iso,
                        image: all_icons[order.iso],
                        amount: order.amount,
                        status: order.status,
                        date: formattedDate,
                        timestamp: order.time_create,
                    })
                });

                if (x.project.status === "success") {
                    const formattedDate = new Date(x.project.time_end * 1000).toLocaleString();

                    formattedIEOList.push({
                        ieoBonus: true,
                        currency: x.orders[0].iso,
                        image: all_icons[x.orders[0].iso],
                        amount: amount + amount_bonus,
                        status: x.project.status,
                        date: formattedDate,
                        timestamp: x.project.time_end,
                        txid: null,
                        txid_explore: null,
                    })
                }
            });

            /**
             * Combine all orders
             * @type {}
             */
            const combinedHistory = [
                ...formattedTransactions,
                ...formattedIEOList
            ];

            // console.log(combinedHistory, currency_id);

            // Sort orders
            combinedHistory.sort((a, b) => b.timestamp - a.timestamp)

            // return orders
            return combinedHistory;
        }

        fetchActivityData().then(history => setItems(history.filter(item => item.currency === ticker)));
    }, [all_icons, transactionsList, ticker])

    /**
     * Render HTML
     */
    return (
        <div className={styles.container}>
            <div className={styles.body}>
                <div className={styles.line}>
                    <h4 className={cn("h4", styles.title)}>{t('wallet.activity.title')}</h4>
                </div>
                {
                    items ? <Table className={styles.table} items={items} currency_id={currency_id} /> : <Loader />
                }
            </div>
        </div>
    );
};

export default Activity;
