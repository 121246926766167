export function updateOrderCreateAnswerOnSocket(order){
    return (dispatch, getState) => {
        let {order_id_create} = getState().quickExchange;

        if (order) {
            if (order.id === order_id_create) {
                if (order.status) {
                    dispatch({
                        type: 'SET_ORDER_CREATE_ANSWER',
                        payload: order
                    });
                }
            }
        }
    }
}