const initialState = {
    candles: [],
    channels: {},
    isSubscribed: false,
    pairs: '',
    interval: 60,
};

export default function (state = initialState, action) {
    const data = action.payload;

    switch (action.type) {
        case 'GRAPH_SET_DATA': {
            //console.log('GRAPH_SET_DATA', data)

            // check pair equals
            if (data.chanel !== undefined) {
                //console.log('GRAPH_SET_DATA', data)

                if (state.channels[data.chanel] === undefined) {
                    //console.log('GRAPH_SET_DATA', state.channels, data.chanel);
                    return state;
                }
            }

            let candles = [];
            let isSubscribed = false;

            if (data.data.length > 0) {
                candles = data.data
                    .sort((a, b) => a.time - b.time)
                    .map(item => ({
                        time: item.time * 1000,
                        open: item.open / 100000000,
                        close: item.close / 100000000,
                        high: item.high / 100000000,
                        low: item.low / 100000000,
                        volume: Math.abs(item.volume / 100000000),
                    }));

                if (data.chanel === `${state.pairs}:${state.interval}`) {
                    isSubscribed = true;
                }
            } else {
                const tmp = [data.data];

                candles = tmp.map(item => ({
                    time: item.time * 1000,
                    open: item.open / 100000000,
                    close: item.close / 100000000,
                    high: item.high / 100000000,
                    low: item.low / 100000000,
                    volume: Math.abs(item.volume / 100000000),
                }));
            }

            return {
                ...state,
                candles: candles,
                isSubscribed: isSubscribed,
            };
        }

        /**
         *
         */
        case 'GRAPH_SET_CHANNEL': {
            for (const k in data) {
                state.channels[k] = data[k];
            }

            return state;
        }

        /**
         *
         */
        case 'GRAPH_REMOVE_CHANNEL': {
            delete state.channels[data];

            return state;
        }

        /**
         *
         */
        case 'GRAPH_INIT_DATA' : {
            for (const k in data) {
                state[k] = data[k];
            }

            return state;
        }

        /**
         * Update any params
         */
        case 'GRAPH_UPDATE_PARAMS' : {
            for (const k in data) {
                state[k] = data[k];
            }

            return state;
        }
        default:
            return state;
    }
}
