import TraderPrepare from "../../utils/TraderPrepare";

const initialState = {
    trades_show: [],
    trades_resp_come: false,
    data_notify_news: [],
    notification_modal: [],
};

export default function (state = initialState, action) {
    const data = action.payload;

    switch (action.type) {
        case 'ADD_NOTIFICATION_MODAL': {
            return {
                ...state,
                notification_modal: data
            }
        }
        case 'ADD_TRADES_ORDERS': {
            // Do deep copy of data
            const orders = JSON.parse(JSON.stringify(data));

            // save new data to store
            return {
                ...state, ...{
                    trades_show: TraderPrepare.renderPrepareTrades(orders)
                }
            };
        }
        case 'RESET_TRADES_ORDERS': {
            return {
                ...state, ...{
                    trades_show: []
                }
            }
        }
        case 'ADD_NEW_ORDER_SHOW': {
            // console.log('ADD_NEW_ORDER_SHOW', data);
            // Do deep copy of order data and do array
            const orders = new Array(JSON.parse(JSON.stringify(data)));

            // render data
            const order_to_add = TraderPrepare.renderPrepareTrades(orders);

            const currentOrders = JSON.parse(JSON.stringify(state.trades_show));
            currentOrders.splice(0, 0, order_to_add[0]);
            if (currentOrders.length >= 25) {
                currentOrders.length = 25;
            }

            return {
                ...state,
                trades_show: currentOrders
            };
        }
        case 'TRADES_ORD_READY_FLAG': {
            return {...state, trades_resp_come: data}
        }
        case 'SET_NOTIFY_NEWS': {
            const newsNew = [];

            for (const item in data) {
                if (data.item && state.notification_modal.includes(data.item.system_notification_id)) {

                } else {
                    newsNew.push(data.item);
                }
            }

            return {...state, data_notify_news: newsNew}
        }
        default:
            return state;
    }
}