import axios from "./Api";

async function fetchCurrentLocation() {
    return axios({ url: '/api/default/location', method: 'POST' })
        .then(response => {
            return response.data;
        })
}

const handleRedirectToLocation = () => {
    fetchCurrentLocation().then((r) => { 
        const location = r.data.country;
        const path = window.location.pathname;
        const searchParams = window.location.search;

        const redirectUrl = process.env['REACT_APP_REDIRECT_ON_LOCATION_' + location];

        if (redirectUrl) {
            window.location.href = 'https://' + redirectUrl + path + searchParams;
        }
    });
}

export default handleRedirectToLocation;