import {lazy} from "react";

/**
 * Auth Pages
 *
 * @type {[]}
 */
export const PublicAuthRoutesList = [
    {
        path: "/registration",
        component: lazy(() => import("../screens/Auth/SignUp"))
    },
    {
        path: "/registration/metamask",
        component: lazy(() => import("../screens/Auth/SignUp"))
    },
    {
        path: "/login",
        component: lazy(() => import("../screens/Auth/SignIn")),
    },
    {
        path: "/login/metamask",
        component: lazy(() => import("../screens/Auth/SignIn")),
    },
    {
        path: "/forgot-password",
        component: lazy(() => import("../screens/Auth/ForgotPassword")),
    },
    {
        path: "/recovery/:code",
        component: lazy(() => import("../screens/Auth/RecoveryPassword")),
    },
    {
        path: "/account-activation/:code",
        component: lazy(() => import("../screens/Auth/AccountActivation")),
    }
];
