const compareEquivalent = [
    'USDT'
];

export default (symbol, state) => {
    const count = state.pairs.length;
    const pairName = symbol + compareEquivalent[0];

    for (let i = 0; i < count; i += 1) {
        if (state.pairs[i].name === pairName) {
            return state.pairs[i].rate;
        }
    }

    return null;
}