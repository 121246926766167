import { useDispatch, useSelector } from "react-redux";

import cn from "classnames";
import styles from "./Alert.module.sass";

import Icon from "../Icon";

import { setAlert } from "../../store/actions/Alert/setAlert";

const Alert = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setAlert(false));
  }

  const alert = useSelector(state => state.account.alert);

  return (
    <div className={styles.alert}>
      <div className={cn(styles.contentContainer, typeof alert === 'string' && styles.textContainer)}>
        {alert}
      </div>
      <div className={styles.close} onClick={handleClose}>
        <Icon name="cross" size={12} />
      </div>
    </div>
  );
}

export default Alert;
