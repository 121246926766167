// import { useSelector } from "react-redux";

import styles from "./LimitsMessage.module.sass";

import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const LimitsMessage = () => {
  // const verificationData = useSelector(state => state.user.verification_data);
  // const settings_withdraw = useSelector(state => state.config.settings_withdraw);
  // const all_currency = useSelector(state => state.config.all_currencies);

  const { t } = useTranslation();

  const isMobile = useMediaQuery({ query: "(max-width: 576px)" });

  return (
    <>
      <div className={styles.limits}>
        {!isMobile &&
          <div className={styles.left}>
            {t('alert.limitsMessage.left')}
          </div>}
        <div className={styles.right}>
          <p className={styles.info}><Trans i18nKey="alert.limitsMessage.info">Please <Link to="/verification" className={styles.link}>complete verification</Link> and get the following limits:</Trans></p>
          {/* <div className={styles.limitsC}>
            <p>{t('alert.limitsMessage.limits.daily')}</p>
            <p>{t('alert.limitsMessage.limits.monthly')}</p>
          </div> */}

          {/* {!verificationData?.statuses?.personal?.approved && <span><Link to="/verification" className={styles.link}>Verify</Link> your personal documents to enable withdrawal of {settings_withdraw.limits.personal_full_name_verified.max} {all_currency[settings_withdraw.limits.personal_address_verified.currency]}</span>}
          {!verificationData?.statuses?.address?.approved && <span><Link to="/verification" className={styles.link}>Verify</Link> your address to enable withdrawal of {settings_withdraw.limits.personal_address_verified.max} {all_currency[settings_withdraw.limits.personal_address_verified.currency]} per day
          </span>} */}
        </div>
      </div>
    </>
  );
}

export default LimitsMessage;