import TradingPrepare from "../../utils/TraderPrepare";

const initialState = {
    current_id: localStorage.getItem('pair-id') || '',
    current_main_name: '',
    current_sec_name: '',
    current_pair_show: {},
    currentPairInfoBoxData: {},
    curr_pair_ready: false,
    ticker_pairs_show: [],
    pairs: [],
    pairs_ready: false,
    ticker_common_filter: '',
    ticker_pairs_filter: '',
    ticker_group_pairs_filter: null,
    stock_volumes: null,
    first_five_by_volume: '',
    currencies_dwm_volumes: null,
    second_currency_list: [], // 'ISO'

    start_ticker: false,
    resp_count: 0,

    coingecko_line_info: null,

    socket_last_close_value: null,
};

export default function (state = initialState, action) {
    const data = action.payload;

    switch (action.type) {
        case 'ADD_PAIR': {
            if (state.pairs === null || state.pairs === undefined) {
                state.pairs = [];
            }

            state.pairs.push(data);

            return state;
        }
        case 'SET_TICKER_PAIRS': {
            // console.log('SET_TICKER_PAIRS length', Object.keys(data).length)
            return {
                ...state,
                ticker_pairs_show: data
            };
        }
        case 'SET_STOCK_VOLUMES': {
            return {...state, stock_volumes: data}
        }
        case 'SET_FIRST_VOLUME_PAIRS': {
            // data.forEach(el => {
            //   state.first_five_by_volume += el.name;
            // })

            return {...state, first_five_by_volume: data}
        }
        case 'SET_CURRENCIES_VOLUMES': {
            return {...state, currencies_dwm_volumes: data}
        }
        case 'CURRENT_PAIR_READY_FLAG': {
            return {...state, curr_pair_ready: data}
        }
        case 'DELETE_CURRENT_PAIR': {
            return {
                ...state, ...{
                    current_main_name: '',
                    current_sec_name: '',
                    //current_id: 1041
                }
            }
        }
        case 'SET_CURRENT_PAIR': {
            //console.log('SET_CURRENT_PAIR', data)
            const pair = TradingPrepare.renderPreparePair(data);

            if (data !== null) {
                return {
                    ...state, ...{
                        current_pair_show: pair,
                        current_id: pair.id,
                        current_main_name: pair.main_name,
                        current_sec_name: pair.second_name,
                        current_main_last: pair.last,
                        curr_pair_ready: true
                    }
                }
            } else {
                return {
                    ...state, ...{
                        curr_pair_ready: false
                    }
                }
            }
        }
        case 'SEARCH_ON_TRADING': {
            return {...state, ticker_common_filter: data}
        }
        case 'SECOND_CURRENCY_TICKER_FILTER': {
            return {...state, ticker_pairs_filter: data}
        }
        case 'GROUP_TICKER_FILTER': {
            return {...state, ticker_group_pairs_filter: data}
        }
        case 'SECOND_CURRENCY_LIST': {
            return {...state, second_currency_list: data}
        }
        case 'SET_START_TICKER': {
            return {...state, start_ticker: data}
        }
        case 'SET_COINGEKO_LINE_INFO': {
            return {...state, coingecko_line_info: data}
        }
        case 'SET_CURRENT_PAIR_INFOBOX_DATA': {
            //console.log('SET_CURRENT_PAIR_INFOBOX_DATA', data);

            return {
                ...state, ...{
                    curr_pair_ready: true,
                    currentPairInfoBoxData: TradingPrepare.renderPreparePair(data)
                }
            }
        }
        case 'SET_SOCKET_LAST_CLOSE_VALUE': {
            return {...state, socket_last_close_value: data}
        }
        default:
            return state;
    }
}
