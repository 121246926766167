import axios from "../../../utils/Api";

export function initialOrdersBookData(pairId) {
    return (dispatch, getState) => {
        let ticker = getState().ticker;

        return new Promise((resolve) => {
            const currentPairId = Number(pairId) || Number(ticker.current_id);
            const limit = getState().ordersBook.book_depth;
            const shortBook = true;

            dispatch({type: 'RESET_BUY_ORDERS_LIST', payload: ''})
            dispatch({type: 'RESET_SELL_ORDERS_LIST', payload: ''})

            axios.get(`${process.env.REACT_APP_API_SOCKET_URL}/book/list?buy=1&sell=1&limit=${limit}&pair_id=${currentPairId}&short=${shortBook}`)
                .then((resp) => {
                    if (
                        resp.status === 200
                        && !resp.error
                        && Number(ticker.current_id) === Number(currentPairId)
                    ) {
                        dispatch({type: 'GOT_INITIAL_ORDERS_LIST', payload: resp.data});

                        dispatch({type: 'BOOK_ORDERS_READY_FLAG', payload: true});

                        resolve('success');
                    } else {
                        dispatch({type: 'BOOK_ORDERS_READY_FLAG', payload: false});

                        resolve('error');
                    }
                })
                .catch(() => {
                    dispatch({type: 'BOOK_ORDERS_READY_FLAG', payload: false});
                });
        });
    }
}
