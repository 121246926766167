import {useState} from "react";
import toast from "react-hot-toast";
import cn from "classnames";
import styles from "./Table.module.sass";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import axios from "../../../../utils/Api";
import getTransactionsList from "../../../../store/actions/User/GetTransactionsList";

const SORTING_FUNCTIONS = {
    'ascending': (a, b, prop) => {
        if (typeof a[prop] === 'string') {
            if (a[prop] > b[prop]) {
                return 1;
            }
            if (a[prop] < b[prop]) {
                return -1;
            }
            return 0;
        } else {
            return +a[prop] - +b[prop];
        }
    },
    'descending': (a, b, prop) => {
        if (typeof a[prop] === 'string') {
            if (a[prop] < b[prop]) {
                return 1;
            }
            if (a[prop] > b[prop]) {
                return -1;
            }
            return 0;
        } else {
            return +b[prop] - +a[prop];
        }
    },
}

const page = 'wallet.activity'

const Table = ({className, items, currency_id}) => {
    /**
     *
     */
    const [sorting, setSorting] = useState({
        prop: 'timestamp',
        descending: true
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const transactionTypeNames = {
        'TRADE': t('transactionTypeNames.trade'),
        'DEPOSIT': t('transactionTypeNames.deposit'),
        'WITHDRAW': t('transactionTypeNames.withdraw'),
        'INTERNAL_TRANSFER': t('transactionTypeNames.transfer'),
        'REFERRAL_BONUS': t('transactionTypeNames.referralBonus'),
        'AFFILIATE_REWARD': t('transactionTypeNames.affiliateReward'),
    }

    /**
     *
     * @param prop
     */
    const handleClick = (prop) => {
        if (sorting.prop === prop) {
            setSorting(prev => ({prop, descending: !prev.descending}))
        } else {
            setSorting({prop, descending: true})
        }
    }

    const handleCancelWithdraw = async (id) => {
        const toastId = toast.loading();

        const response = await axios.post('/api/withdraw/delete', { id })

        if (response.data.status) {
            toast.success(t(`${page}.canceledMessage`), { id: toastId })
        } else {
            toast.error(Object.values(response.data.errors ?? ['Error'])[0], { id: toastId })
        }
        dispatch(getTransactionsList(currency_id))
    }

    items.sort((a, b) => {
        return (
            (sorting.descending) ?
                SORTING_FUNCTIONS.descending(a, b, sorting.prop) :
                SORTING_FUNCTIONS.ascending(a, b, sorting.prop)
        )
    });

    /**
     *
     * @param prop
     * @returns {string}
     */
    const checkClassName = (prop) => {
        return (sorting.prop === prop) ? (sorting.descending ? 'up' : 'down') : ''
    }

    /**
     * Render HTML
     */
    return (
        <div className={cn(className, styles.table)}>
            <div className={styles.row}>
                <div className={styles.col}>
                    <div>{t(`${page}.table.type`)}</div>
                </div>
                <div className={styles.col}>
                    <div
                        className={`sorting ${checkClassName('currency')}`}
                        onClick={() => handleClick('currency')}
                    >
                        {t(`${page}.table.coin`)}
                    </div>
                </div>
                <div className={styles.col}>
                    <div
                        className={`sorting ${checkClassName('amount')}`}
                        onClick={() => handleClick('amount')}
                    >
                        {t(`${page}.table.amount`)}
                    </div>
                </div>
                <div className={styles.col}>
                    <div
                        className={`sorting ${checkClassName('status')}`}
                        onClick={() => handleClick('status')}
                    >
                        {t(`${page}.table.status`)}
                    </div>
                </div>

                <div className={styles.col}>
                    <div
                        className={`sorting ${checkClassName('txid')}`}
                        onClick={() => handleClick('txid')}
                    >
                        {t(`${page}.table.txid`)}
                    </div>
                </div>

                <div className={styles.col}>
                    <div
                        className={`sorting ${checkClassName('timestamp')}`}
                        onClick={() => handleClick('timestamp')}
                    >
                        {t(`${page}.table.date`)}
                    </div>
                </div>
            </div>
            {items.map((x, index) => {
                if (x.type_raw === "WITHDRAW") {
                    console.log(x);
                }

                return (
                <div className={styles.row} key={index}>
                    <div className={styles.col}>
                        {x.type && (
                            <div className={"category-blue"}>{x.type}</div>
                        )}
                        {x.ieoSell && (
                            <div className="category-red">{t(`${page}.table.launchpad`)}</div>
                        )}
                        {x.ieoBonus && (
                            <div className="category-green">{t(`${page}.table.launchpad`)}</div>
                        )}
                    </div>
                    <div className={styles.col}>
                        <div className={styles.item}>
                            <div className={styles.icon}>
                                <img loading="lazy" src={x.image} alt="Coin" />
                            </div>
                            <div className={styles.nowrap}>{x.currency}</div>
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.label}>{t(`${page}.table.amount`)}</div>
                        {(x.withdraw || x.ieoSell || x.sell) && "-" || ""}
                        {x.amount}
                    </div>
                    <div className={styles.col}>
                        <div className={styles.label}>{t(`${page}.table.status`)}</div>
                        <div>
                            <span>
                                {x.status}&nbsp;&nbsp;
                                {(x.type_raw === 'WITHDRAW' && [0, 2, 3, 4, 5, 8, 9, 13].includes(x.status_raw)) && 
                                    <button className={cn("button button-small button-stroke", styles.cancelBtn)} onClick={() => handleCancelWithdraw(x.id)}>{t(`${page}.cancel`)}</button>}
                            </span>
                            <br />
                            <span className={styles.comment}>{x.comment_from_admin}</span>
                        </div>
                    </div>

                    <div className={styles.col}>
                        <div className={styles.label}>{t(`${page}.table.txid`)}</div>
                        {x.txid && <a className={cn(x.txid_explore && styles.link)} href={x.txid_explore} target="_blank">{x.txid}</a>}
                    </div>
                    <div className={styles.col}>
                        <div className={styles.label}>{t(`${page}.table.date`)}</div>
                        {x.date}
                    </div>
                </div>
            )})}
        </div>
    );
};

export default Table;
