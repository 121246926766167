import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import session_manager from "../utils/SessionManager";
import SeoMetaDataHandle from "../SeoMetaDataHandle";

const AuthMiddleware = ({ component: Component, layout: Layout, isAuthProtected, authCheck, headerHide, footerHide, ...rest }) => {
    return (
        <Route
            render={props => {
                // Check if user is not logged, then forbidden private pages
                if (isAuthProtected && !session_manager.isLoggedIn() && window.location.pathname !== '/') {
                    const queryParams = new URLSearchParams();
                    queryParams.set('target', window.location.href);

                    return <Redirect to={{ pathname: "/login", search: queryParams.toString(), state: { from: props.location } }} />;
                }

                // Check if user is logged, then forbidden to open the auth pages, we make a redirect to markets
                if(!isAuthProtected && authCheck && session_manager.isLoggedIn()){
                    return <Redirect to={{ pathname: "/markets", state: { from: props.location } }} />;
                }

                return (
                    <Layout headerHide={headerHide} footerHide={footerHide}>
                        <SeoMetaDataHandle />
                        <Component {...props} />
                    </Layout>
                );
            }}
            {...rest}
        />
    );
};

AuthMiddleware.propTypes = {
    isAuthProtected: PropTypes.bool,
    component: PropTypes.any,
    location: PropTypes.object,
    layout: PropTypes.any,
};

export default AuthMiddleware;
