import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getUserBalances} from "../../../store/utils/GetUserBalances";
import TraderPrepare from "../../../utils/TraderPrepare";

const page = "wallet.transfer";

const MaxAmountButton = ({ticker, walletType, className, handleSetAmount}) => {
    const {t} = useTranslation();

    const balanceData = useSelector(state => getUserBalances(state, ticker));
    const balance = balanceData[walletType];

    const tickerDecimalRounding = useSelector(state => {
        const tickerId = state.config.all_currencies_flip[ticker];

        return state.config.currency_data[tickerId].decimal_rounding
    });

    /**
     * Render HTML
     */
    return (
        <button
            className={className}
            type='button'
            onClick={() => handleSetAmount(TraderPrepare.fixedFromInt(balance.total_available_num, tickerDecimalRounding))}
        >
            {t(`${page}.maxAmount`)}
        </button>
    );
}

export default MaxAmountButton;