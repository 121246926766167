const initialState = {
    pairs_quick_exchange: null,
    order_id_create: null,
    order_create_answer: null,
    qe_main_data: null,
};

export default function (state = initialState, action) {
    const data = action.payload;

    switch (action.type) {
        case 'SET_PAIRS_QUICK_EXCHANGE': {
            return {...state, pairs_quick_exchange: data}
        }
        case 'SET_ORDER_CREATE_ID': {
            return {...state, order_id_create: data}
        }
        case 'SET_ORDER_CREATE_ANSWER': {
            return {...state, order_create_answer: data}
        }
        case 'SET_MAIN_DATA_QE': {
            return {...state, qe_main_data: data}
        }
        case 'DEL_MAIN_DATA_QE': {
            return {...state, qe_main_data: null}
        }
        default:
            return state;
    }
}