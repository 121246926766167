import {updateSocketSubscribes} from "../Sockets/UpdateSocketSubscribes";
import {socket} from "../../../context/socket";

export function updateGraphOnSocket(message){
    return (dispatch, getState) => {
        let tickerState = getState().ticker;

        if (message.chanel || (Number(message.room.split(':')[2]) === Number(tickerState.current_id))) {
            // console.log('updateGraphOnSocket: is channel, room and current ticker', message);
            dispatch({
                type: 'GRAPH_SET_DATA',
                payload: message
            });
        } else {
            // console.log('updateGraphOnSocket')
            dispatch(updateSocketSubscribes(socket));
        }
    }
}