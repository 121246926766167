//import store from '../store/index';
import bs58 from 'bs58';

const getAuthData = () => {
    // Get private Key from storage
    const privateKey = localStorage.getItem('privateKey') || '';

    // Return empty object, if not exist
    if(!privateKey){
        return {};
    }

    // Get auth data
    const decoder = new TextDecoder();
    const data = decoder.decode(bs58.decode(privateKey));

    return JSON.parse(data);
}

const session_manager = {
    setToken(token) {
        localStorage.setItem('user-token', token);
        //store.commit('SET_USER_AUTH', true);

        return 'token_set';
    },
    getToken() {
        return getAuthData().token || null;
    },
    removeToken() {
        localStorage.removeItem('privateKey');
        localStorage.removeItem('user-token');
        localStorage.removeItem('session');
        localStorage.removeItem('user-mail');
        localStorage.removeItem('two_f_need');
        localStorage.removeItem('user-chat');
        //store.commit('SET_USER_AUTH', false);
    },
    set2FactorRequirement(type) {
        localStorage.setItem('two_f_need', type);
    },
    remove2FactorRequirement() {
        localStorage.removeItem('two_f_need');
    },
    isLoggedIn() {
        const authData = getAuthData();

        return !!(authData.token && authData.secret);
        //return !!(authData.token && authData.secret && !localStorage.getItem('two_f_need'));
    },
    getPublicStr() {
        return getAuthData().secret || null;
    },
    setCurrentPairId(id) {
        localStorage.setItem('pair-id', id);
    },
    getCurrentPairId() {
        localStorage.getItem('pair-id');
    },
    setDemoFlag(str) {
        localStorage.setItem('demo-mode', str);
    },
    getDemoFlag() {
        return localStorage.getItem('demo-mode');
    },
    removeDemoFlag() {
        localStorage.removeItem('demo-mode');
    },
};

export default session_manager;
