//import TraderPrepare from "../../../utils/TraderPrepare";

//const walletTypes = [
//    'spot',
//    'funding',
//    'margin'
//];

export function setDemoBalances (){
    return (dispatch, getState) => {
        const config = getState().config;

        for (const id in config.all_currencies) {
            //console.log(id);

            // const balances = {
            //     iso3: config.all_currencies[id],
            //    // currency: currency
            // };

            // for (const type in walletTypes){
            //     balances[type] = {
            //         total_balance: TraderPrepare.fixedFromInt(0, 8),
            //         total_available: TraderPrepare.fixedFromInt(0, 8),
            //         total_available_usdt: TraderPrepare.fixedFromInt(0, 2),
            //         total_balance_usdt: TraderPrepare.fixedFromInt(0, 2)
            //     }
            // }

            //console.log(balances);

            // dispatch({
            //     type: 'UPDATE_USER_BALANCES',
            //     payload: balances
            // });
        }

        dispatch({
            type: 'BALANCES_READY_FLAG',
            payload: true
        });

        return true;
    }
}