import axios from '../../../utils/Api';

export function fetchIEO(token, sign) {
  return (dispatch) => {
    axios.post('/api/ieo', {}, (token && sign) && {
      headers: {
        'Login-Token': token,
        'X-Auth-Jwt': sign
      }
    })
      .then(response => {
        return response.data;
      })
      .then(data => dispatch({
        type: 'FETCH_IEO',
        payload: data
      }))
      .catch(() => { });
  }
}

export function fetchIEOView(link, token, sign) {
  return (dispatch) => {
    axios.post('/api/ieo/view', { link }, (token && sign) && {
      headers: {
        'Login-Token': token,
        'X-Auth-Jwt': sign
      }
    })
      .then(response => {
        return response.data;
      })
      .then(data => dispatch({
        type: 'FETCH_IEO_VIEW',
        payload: data
      }))
      .catch(() => { });
  }
}

export function fetchUserIEOList(token, sign) {
  return (dispatch) => {
    axios.post('/api/userieo/my-list', {}, (token && sign) && {
      headers: {
        'Login-Token': token,
        'X-Auth-Jwt': sign
      }
    })
      .then(response => {
        return response.data;
      })
      .then(data => dispatch({
        type: 'FETCH_USER_IEO_LIST',
        payload: data
      }))
      .catch(() => { });
  }
}
