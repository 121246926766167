import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import OutsideClickHandler from "react-outside-click-handler";
import { Link } from "react-router-dom";

import cn from "classnames";

import styles from "./AddressBookDropdown.module.sass";
import Icon from "../../Icon";
import clevertap from "clevertap-web-sdk";

const page = 'profile.addressManagement';

const network_type_names = {
  0: 'Mainnet',
  1: 'ERC 20',
  2: 'TRC 20',
  3: 'BNB',
  4: 'BEP 20',
}

const network_type_names_flip = {
  'Mainnet': 0,
  'ERC 20': 1,
  'TRC 20': 2,
  'BNB': 3,
  'BEP 20': 4,
}

const AddressBookDropdown = ({ className, classLabel, value, setValue, options, label, classDropdownHead, classDropdownArrow, classDropdownBody, classDropdownOption, classDropdownOptionsContainer, coin, disabled }) => {
  const [visible, setVisible] = useState(false);

  const accountInfo = useSelector(state => state.user.account_info);

  const handleClick = (value) => {
    clevertap.event.push("user_clicked_wl_address_in_withdraw", {
      userId: accountInfo?.profile?.id
    });
    setValue(value);
    setVisible(false);
  };

  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && <div className={cn(classLabel, styles.label)}>{label}</div>}
      <div className={cn(styles.dropdown, className, {
        [styles.active]: visible,
      })}>
        <div
          className={cn(
            classDropdownHead,
            styles.head,
            disabled ? styles.disabled : null
          )}
          onClick={() => { if (!disabled) { setVisible(!visible) } }}
        >
          <div className={styles.selection}>{value}</div>
          <div className={cn(styles.arrow, classDropdownArrow)}>
            <Icon name="arrow-down" size="24" />
          </div>
        </div>
        <div className={cn(classDropdownBody, styles.body)}>
          <div className={styles.contentInTop}>
            <span className={cn(styles.title)}>Choose from address book</span>
          </div>
          <div className={cn(classDropdownOptionsContainer)}>
            <div className={styles.table}>
              {options && options
                .filter((x) => (x.iso === coin))
                .map((x, index) => {
                  return (
                    <div
                      className={cn(classDropdownOption, styles.row, styles.option, { [styles.selected]: x === value })}
                      onClick={() => handleClick(x, index)}
                      key={index}
                    >
                      <div className={cn(styles.col, styles.nameCol)}>
                        <span className={styles.name}>
                          {x.name}
                        </span>
                        <span className={cn("category", styles.category)}>
                          {x.is_white ? t(`${page}.whitelisted`) : t(`${page}.notWhitelisted`)}
                        </span>
                        {typeof x.network_type === 'number' && (
                          <span className={cn("category", styles.category)}>
                            {network_type_names[x.network_type]}
                          </span>
                        )}
                      </div>
                      <div className={cn(styles.col, styles.addressCol)}>
                        <div>
                          <span className={styles.label}>
                            {t(`${page}.sorting.address`)}
                          </span>

                          {/* address for desktop */}
                          <span className={styles.fullAddress}>
                            {x.address}
                          </span>

                          {/* address for mobile */}
                          <span className={styles.shortAddress}>
                            {(x.address && x.address.length > 20)
                              ? x.address.substring(0, 9).concat('...') + x.address.substring(x.address.length - 8, x.address.length)
                              : x.address}
                          </span>
                        </div>
                        <div>
                          <span className={styles.label}>
                            {t(`${page}.sorting.coin`)}
                          </span>
                          <span className={styles.coin}>
                            {x.iso}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              <div className={styles.addNewAddress}>
                <Link to="/address-management" target="_blank">Add new address</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler >
  );
};

export default AddressBookDropdown;
