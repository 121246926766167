const initialState = {
    voting_number: null,
    voting_coins: null,
    voting_round: null,

    user_voting: {},
    voting_history: null,
    voting_history_item: {
        round: null,
        list: []
    },
};

export default function (state = initialState, action) {
    const data = action.payload;

    switch (action.type) {
        case 'SET_COINS_VOTING' : {

            return {
                ...state,
                voting_coins: data.list,
                voting_number: data.number,
                voting_round: data.round
            };
        }

        case 'SET_USER_VOTING' : {
            return {
                ...state,
                user_voting: data
            };
        }

        case 'SET_DATE_VOTING_HISTORY' : {
            return {
                ...state,
                voting_history: data
            };
        }

        case 'SET_DATE_VOTING_HISTORY_ITEM' : {
            return {
                ...state,
                voting_history_item: data
            };
        }

        default:
            return state;
    }
}