import TraderPrepare from "../../../utils/TraderPrepare";

export function updateActiveOrdersData (order){
    return (dispatch, getState) => {
        const config = getState().config;
        const user = getState().user;

        const orders_list = user.aod;
        if (orders_list[order.id]) {
            dispatch({
                type: 'DELETE_ACTIVE_ORDER',
                payload: { index: order.id }
            });
        }

        const o_array = [order];
        const ready_order = TraderPrepare.prepareUsersOrders(o_array, config.all_pairs);

        dispatch({
            type: 'ADD_ORDER_ACTIVE',
            payload: {
                order: ready_order,
                index: order.id
            }
        });

        dispatch({
            type: 'ACTIVE_ORD_READY_FLAG',
            payload: true
        });
    }
}
