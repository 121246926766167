const initialState = {
  leaderboard: {},
  my_leaderboard: {},
  gifts: {
    available: [],
    my: [],
    my_vouchers: [],
  },
  my_transactions: {
    currentPageResult: [],
    totalPages: null,
    page: null
  }
};

export default function (state = initialState, action) {
  const data = action.payload;

  switch (action.type) {
      case 'FETCH_MY_LEADERBOARD':
          return {
              ...state,
              my_leaderboard: data
          }
      case 'FETCH_MY_GIFTS':
          return {
              ...state,
              gifts: {
                ...state.gifts,
                my: data
              }
          }
      case 'FETCH_MY_VOUCHERS':
          return {
              ...state,
              gifts: {
                ...state.gifts,
                my_vouchers: data
              }
          }
      case 'FETCH_MY_TRANSACTIONS':
          return {
              ...state,
              my_transactions: data
          }
      case 'FETCH_GIFTS_AVAILABLE':
          return {
              ...state,
              gifts: {
                ...state.gifts,
                available: data
              }
          }
      default:
          return state;
  }
}