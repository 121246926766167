import axios from "../../../utils/Api";
import session_manager from "../../../utils/SessionManager";
import { setDemoBalances } from "./SetDemoBalances";
import { prepareUserBalances } from "../../utils/PrepareUserBalances";
import {buildQueryString} from "../../utils/BuildQueryString";

/**
 *
 * @param payload
 * @param token
 * @param sign
 * @returns {function(*, *): Promise<unknown>}
 */
export function fetchUserBalances(payload = null, token, sign) {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            if (session_manager.getDemoFlag()) {
                dispatch(setDemoBalances());
            } else {
                let query = '';

                if (payload) {
                    query = `?` + buildQueryString(payload);
                }

                axios.get(`/v3/users/balances-volume${query}`)
                    .then((resp) => {
                        if (resp.status === 200 && resp.data.list) {
                            prepareUserBalances(dispatch, resp.data, getState);

                            resolve(resp.data);
                        } else {
                            // console.log('UPDATE_BALANCE_ERROR no deposit list in response');
                        }
                    })
                    .catch((err) => {
                        prepareUserBalances(dispatch, null, getState);

                        dispatch({
                            type: 'BALANCES_READY_FLAG',
                            payload: true
                        });
                    });
            }
        })
    }
}
