import axios from '../../../utils/Api';

function getTransactionsList(currency_id) {
  return (dispatch) => {
    axios.get('/api/transactions/list', { params: { currency_id } })
      .then(response => {
        return response.data;
      })
      .then(data => dispatch({
        type: 'GET_TRANSACTIONS_LIST',
        payload: data
      }))
      .catch(() => { });
  }
}

export default getTransactionsList;