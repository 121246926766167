import axios from '../../../utils/Api';

function fetchUserAccountInfo() {
    return (dispatch) => {
        axios.get('/api/account/index')
            .then(response => {
                return response.data;
            })
            .then(data => dispatch({
                type: 'FETCH_ACCOUNT_INFO',
                payload: data
            }))
            .catch(() => {
            });
    }
}

export function fetchUserAccountSettings() {
    return (dispatch) => {
        axios.get('/api/account/index')
            .then(response => {
                return response.data;
            })
            .then(data => dispatch({
                type: 'FETCH_ACCOUNT_SETTINGS',
                payload: data.data
            }))
            .catch(() => {});
    }
}

export function fetchUserAccountInfoWithToken(token, sign) {
    return (dispatch) => {
        axios.get(`/api/account/index`, {
            headers: {
                'Login-Token': token,
                'X-Auth-Jwt': sign
            }
        })
            .then(response => {
                return response.data;
            })
            .then(data => dispatch({
                type: 'FETCH_ACCOUNT_INFO',
                payload: data
            }))
            .catch(() => {
            });
    }
}

export function fetchAddressBook() {
    return (dispatch) => {
        axios({url: '/api/account/index', method: 'POST'})
            .then(response => {
                return response.data;
            })
            .then(data => dispatch({
                type: 'FETCH_ADDRESS_BOOK',
                payload: data
            }))
            .catch(() => {
            });
    }
}

export function updateAccountEmail(data) {
    return (dispatch) => dispatch({
        type: 'UPDATE_ACCOUNT_EMAIL',
        payload: {email: data.email}
    })
}

export default fetchUserAccountInfo;