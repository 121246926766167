const initialState = {
    to_subscribe: [
        // 'short_book_',
        'book_delta_',
        'book_snapshot_',
        'hist_'
    ], // all that needs update
    socket_connected: false,
    subscribed: [],
};

export default function (state = initialState, action) {
    const data = action.payload;

    switch (action.type){
        case 'ADD_TO_SUBSCRIBED': {
            state.subscribed.push(data);

            return state;
        }
        case 'RESET_SUBSCRIBED': {
            return {...state, subscribed: []}
        }
        case 'SOCKET_CONNECT': {
            return {...state, socket_connected: true}
        }
        case 'SOCKET_DISCONNECT': {
            return {...state, socket_connected: false}
        }
        default:
            return state;
    }
}
