import axios from '../../../utils/Api';

function fetchVerification() {
  return (dispatch) => {
    axios({ url: '/api/verification', method: 'POST' })
      .then(response => {
        return response.data;
      })
      .then(data => dispatch({
        type: 'FETCH_VERIFICATION',
        payload: data
      }))
      .catch(() => { });
  }
}

export function fetchVerificationWithToken(token, sign) {
  return (dispatch) => {
    axios.get(`/api/verification`, {
      headers: {
        'Login-Token': token,
        'X-Auth-Jwt': sign
      }
    })
      .then(response => {
        return response.data;
      })
      .then(data => dispatch({
        type: 'FETCH_VERIFICATION',
        payload: data
      }))
      .catch(() => { });
  }
}

export default fetchVerification;