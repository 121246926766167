import { createSelector } from 'reselect';

const selectUser = (state) => state.user;

export const getUserBalances = createSelector(
    selectUser,
    (user, ticker) => ticker,
    (user, __, type = null) => type,
    (user, ticker, type) => {
        if (ticker && user.user_balances[ticker]) {
            if (type) {
                return user.user_balances[ticker][type];
            } else {
                return user.user_balances[ticker];
            }
        }

        return null;
    }
);
