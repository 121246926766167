import TradingPrepare from "../../../utils/TraderPrepare";
import {updateCurrentPair} from "./UpdateCurrentPair";
import getPairDataBySymbol from "../../utils/TickerGetPairDataBySymbol";

let firstTime = true;

export function updateTicker(data){
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            let ticker = getState().ticker;
            const config = getState().config;
            let setCurrentPairData = null;

            // while response data
            for (const el in data) {
                let found = false;

                for (const s_pair in ticker.pairs) {
                    if (data[el].id === ticker.pairs[s_pair].id) {
                        found = true;

                        ticker.pairs.splice(s_pair, 1, TradingPrepare.filterPairSocketData(data[el]));
                        
                        if (Number(ticker.pairs[s_pair].id) === Number(ticker.current_id)) {
                            setCurrentPairData = data[el];
                        }

                        break;
                    }
                }

                if (!found && TradingPrepare.isPairType(data[el])) {
                    dispatch({
                        type: 'ADD_PAIR',
                        payload: TradingPrepare.filterPairSocketData(data[el])
                    });

                    if (Number(data[el].id) === Number(ticker.current_id)) {
                        setCurrentPairData = data[el];
                    }
                }
            }

            // console.log('updateTicker setCurrentPairData', setCurrentPairData);
            if (setCurrentPairData) {
                dispatch({
                    type: 'SET_CURRENT_PAIR_INFOBOX_DATA',
                    payload: setCurrentPairData
                });

                // update history, update book
                if (firstTime) {
                    dispatch(updateCurrentPair(setCurrentPairData.id));

                    firstTime = false;
                }
            }

            ticker.pairs_ready = true;

            const volumes = {
                month: 0,
                day: 0,
                week: 0
            };
            const max_volumes = [];

            const currencyVolumes = {};
            ticker.pairs.forEach((el) => {
                if (currencyVolumes[el.main.iso3]) {
                    currencyVolumes[el.main.iso3].d += Number(el.volumes.d) / (10 ** 8);
                    currencyVolumes[el.main.iso3].m += Number(el.volumes.m) / (10 ** 8);
                    currencyVolumes[el.main.iso3].w += Number(el.volumes.w) / (10 ** 8);
                } else {
                    currencyVolumes[el.main.iso3] = {d: 0, m: 0, w: 0};
                    currencyVolumes[el.main.iso3].d = Number(el.volumes.d) / (10 ** 8);
                    currencyVolumes[el.main.iso3].m = Number(el.volumes.m) / (10 ** 8);
                    currencyVolumes[el.main.iso3].w = Number(el.volumes.w) / (10 ** 8);
                }
            });

            dispatch({
                type: 'SET_CURRENCIES_VOLUMES',
                payload: currencyVolumes
            });

            let pairs_for_render = ticker.pairs.slice(0);

            // Filter the array, remove elements that are not in the response
            pairs_for_render = pairs_for_render.filter(pair => {
                return data.some(item => Number(item.id) === Number(pair.id));
            });

            for (const i in pairs_for_render){
                const pair_rate = getPairDataBySymbol(pairs_for_render[i].second.iso3, ticker);
                const nice_name = pairs_for_render[i].main ? config.nice_ccy_names[pairs_for_render[i].main.iso3] : '';

                pairs_for_render[i] = TradingPrepare.renderPreparePair(pairs_for_render[i], pair_rate, nice_name);

                if (pairs_for_render[i].volume) {
                    max_volumes.push(pairs_for_render[i]);
                }

                if (!pairs_for_render[i] || !pairs_for_render[i].volumes) {
                    continue;
                }

                volumes.day += pairs_for_render[i].volumes.day;
                volumes.week += pairs_for_render[i].volumes.week;
                volumes.month += pairs_for_render[i].volumes.month;
            }

            dispatch({
                type: 'SET_TICKER_PAIRS',
                payload: pairs_for_render
            });

            // total stock volumes in usd
            dispatch({
                type: 'SET_STOCK_VOLUMES',
                payload: {
                    day: TradingPrepare.numberAddCommas(volumes.day.toFixed(2)),
                    week: TradingPrepare.numberAddCommas(volumes.week.toFixed(2)),
                    month: TradingPrepare.numberAddCommas(volumes.month.toFixed(2))
                }
            });

            max_volumes.sort((a, b) => b.volume - a.volume);
            // max_volumes.length = 5;
            dispatch({
                type: 'SET_FIRST_VOLUME_PAIRS',
                payload: max_volumes
            });

            resolve('');
        })
    }
}