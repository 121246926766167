import { setCurrentBalance } from "../actions/User/SetCurrentBalance";
import TraderPrepare from "../../utils/TraderPrepare";

export function prepareUserBalances(dispatch, data, getState) {
    const configs_ready = getState().config.configs_ready.all_currencies;

    if (!configs_ready) {
        return;
    }

    const all_currency_flip = getState().config.all_currencies_flip;
    const currency_data = getState().config.currency_data;
    const currency_decimals = getState().config.currency_decimals;

    let tickers_balances = {};
    let total_balances = {
        total: {
            btc: data ? data.total.BTC ?? 0 : 0,
            usdt: data ? data.total.USDT ?? 0 : 0,
        },
        spot: {
            usdt: 0,
            btc: 0,
        },
        margin: {
            usdt: 0,
            btc: 0,
        },
        funding: {
            usdt: 0,
            btc: 0,
        }
    }

    const usdtId = all_currency_flip['USDT'];
    const btcId = all_currency_flip['BTC'];

    const usdtDecimalRounding = currency_data[usdtId]?.decimal_rounding || 5

    if(data){
        for (const key in data.list) {
            const item = data.list[key];

            if (!item.hasOwnProperty('currency')) {
                return;
            }

            const iso3 = item.currency.iso3;
            if (!tickers_balances.hasOwnProperty(iso3)) {
                tickers_balances[iso3] = {
                    iso3: iso3,
                    currency: item.currency
                };
            }

            const type = item.deposit_type
            const coinId = all_currency_flip[iso3];

            if (!tickers_balances[iso3].hasOwnProperty(type)) {
                const iso3DecimalRounding = currency_data[coinId]?.decimal_rounding ?? currency_decimals[iso3];

                tickers_balances[iso3][type] = {
                    // decimal: currency_data[usdtId].decimal_rounding,
                    decimal: currency_data[coinId]?.decimal_rounding ?? currency_decimals[iso3],
                    decimal_rounding: usdtDecimalRounding,

                    total_balance: TraderPrepare.formatNumCurrency(item.balance, currency_decimals[iso3]),
                    total_balance_usdt: (item.balances && 'USDT_total' in item.balances) ? TraderPrepare.formatNumCurrency(item.balances.USDT_total, usdtDecimalRounding) : null,
                    total_balance_num: +(item.balance),
                    total_balance_usdt_num: (item.balances && 'USDT_total' in item.balances) && +(item.balances.USDT_total),

                    total_available: TraderPrepare.formatNumCurrency(item.balance_available, currency_decimals[iso3]),
                    total_available_usdt: (item.balances && 'USDT' in item.balances) ? TraderPrepare.formatNumCurrency(item.balances.USDT, usdtDecimalRounding) : null,
                    total_available_num: +(item.balance_available),
                    total_available_usdt_num: +(item.balances.USDT),

                    frozen: TraderPrepare.formatNumCurrency(item.balance - item.balance_available, currency_decimals[iso3]),
                    frozen_num: +(TraderPrepare.formatNumCurrency(item.balance - item.balance_available, currency_decimals[iso3])),
                    frozen_usdt: (item.balances && 'USDT' in item.balances) ? TraderPrepare.formatNumCurrency(item.balances.USDT_total - item.balances.USDT, usdtDecimalRounding) : null,
                    frozen_usdt_num: +((item.balances && 'USDT' in item.balances) ? TraderPrepare.formatNumCurrency(item.balances.USDT_total - item.balances.USDT, usdtDecimalRounding) : null),
                }
            }

            if (total_balances[type] !== undefined) {
                total_balances[type] = {
                    usdt: data.total_by_wallet_type?.[type]?.USDT,
                    btc: data.total_by_wallet_type?.[type]?.BTC,
                };
            }
        }
    }

    for (let key in total_balances) {
        total_balances[key].btc = TraderPrepare.formatNumCurrency(total_balances[key].btc, currency_data[btcId]?.decimal_rounding || 5);
        total_balances[key].usdt = TraderPrepare.formatNumCurrency(total_balances[key].usdt, usdtDecimalRounding);
    }

    dispatch({
        type: 'SET_CURRENT_BALANCE_CONVERTED',
        payload: total_balances
    });

    dispatch({
        type: 'UPDATE_USER_BALANCES',
        payload: tickers_balances
    });

    dispatch(setCurrentBalance());
    dispatch({
        type: 'SET_CURRENT_BALANCE_PNL',
        payload: data ? data.pnl : 0
    });
}