import axios from "../../../utils/Api";
import TradingPrepare from "../../../utils/TraderPrepare";
import {updateTicker} from "./UpdateTicker";

export function getTickers() {
    return (dispatch, getState) => {
        return new Promise((resolve) => {
            const tickerState = getState().ticker;

            dispatch({
                type: 'SET_START_TICKER',
                payload: true
            });

            axios.get('/api/default/ticker')
                .then(response => {
                    if (
                        response.status === 200
                        && !response.error
                    ) {
                        // console.log('getTickers response', response.data.data)
                        dispatch(updateTicker(response.data.data));

                        if (tickerState.second_currency_list.length === 0) {
                            dispatch({
                                type: 'SECOND_CURRENCY_LIST',
                                payload: TradingPrepare.makeSecondCurrList(response.data.data)
                            });
                        }

                        // if (rootGetters.tickerPairsRespErr) {
                        //     dispatch({type: 'SERVER_TICKER_PAIRS_ERR', payload: false});
                        //     dispatch({type: 'SERVER_CURRENT_PAIR_ERR', payload: false});
                        // }

                        resolve('ok');
                    } else {
                        resolve('error');
                    }
                })
                .catch(()=>{
                    dispatch({
                        type: 'SECOND_CURRENCY_LIST',
                        payload: []
                    });

                    // dispatch({type: 'SET_TICKER_PAIRS', payload: []});
                    // dispatch({type: 'SET_CURRENT_PAIR', payload: null});

                    dispatch({type: 'SERVER_TICKER_PAIRS_ERR', payload: true});
                    dispatch({type: 'SERVER_CURRENT_PAIR_ERR', payload: true});

                    resolve('error');
                });
        })
    }
}
